import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";

import LangContext from "../context/LangContext";
import { DirectusPhiloContent } from "../models";

interface Props {
  story: DirectusPhiloContent;
}
const Story = (props: Props) => {
  const lang = useContext(LangContext).lang;
  const data = props.story.translations.find(t => t.language === lang);
  return (
    <>
      <Container fluid={true} className="story">
        {data && (
          <>
            <Row>
              <Col>
                <h1>{data.title}</h1>
                <h2>{data.subtitle}</h2>
              </Col>
            </Row>
            <Row className="storyContent">
              <Col lg={{ span: 3, offset: 2 }} sm={12}>
                <img alt="storyIllustration" src={data.illustration.data.full_url} />
              </Col>
              <Col lg={6} sm={12}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<div> ${data.content} </div>`,
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Story;
