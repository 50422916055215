import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";

import LangContext, { Labels } from "../context/LangContext";
import { DirectusPhiloContent, Translation } from "../models";

interface Props {
  services: DirectusPhiloContent[];
}

const renderService = (service: Translation, index: number) => (
  <Col key={index} className="serviceCard" lg={2} sm={12}>
    {service && (
      <>
        <Row>
          <img src={service.illustration.data.full_url} alt={service.title} />
        </Row>
        <Row>
          <h5>{service.title}</h5>
        </Row>
        <Row>
          <p>{service.subtitle}</p>
        </Row>
      </>
    )}
  </Col>
);

const Services = (props: Props) => {
  const lang = useContext(LangContext).lang;
  const services = props.services.map(i => i.translations.find(t => t.language === lang));
  const labels: Labels = useStaticQuery(graphql`
    query ServiceQuery {
      site {
        siteMetadata {
          services {
            fr
            de
            it
            en
          }
        }
      }
    }
  `).site.siteMetadata.services;

  return (
    <>
      <Container fluid={true} className="services">
        {services && (
          <>
            <Row>
              <h1>{labels[lang]}</h1>
            </Row>
            <Row className="servicesRow">{services.map(renderService)}</Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Services;
