import React from "react";
import { PageProps, graphql } from "gatsby";
import { Alert } from "react-bootstrap";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Services from "../components/services";
import Story from "../components/story";
import banner from "../images/banner.jpg";
import { AllDirectusPhiloContent, DirectusPhiloContent } from "../models";

const getStory = (list: DirectusPhiloContent[]): DirectusPhiloContent =>
  list.find(l => l.name === "story");

const getServices = (list: DirectusPhiloContent[]): DirectusPhiloContent[] =>
  list.filter(l => l.name === "services").sort((a, b) => a.order - b.order);

const Index: React.FC<PageProps<AllDirectusPhiloContent>> = ({ data }) => {
  return (
    <Layout>
      <SEO title="Accueil" />
      <img src={banner} className="banner" alt="banner" />
      {/* <Alert variant="warning" style={{ textAlign: "center", fontWeight: 500, fontSize: 20 }}>
        Vacances d'été du 23 juin au 12 juillet. Toute l'équipe du Philosophe vous souhaite un bel été.
      </Alert> */}
      <Story story={getStory(data.allDirectusPhiloContent.nodes)} />
      <Services services={getServices(data.allDirectusPhiloContent.nodes)} />
    </Layout>
  );
};

export default Index;

export const query = graphql`
  {
    allDirectusPhiloContent(filter: { page: { eq: "home" } }) {
      nodes {
        name
        page
        order
        translations {
          language
          title
          subtitle
          content
          illustration {
            data {
              full_url
            }
          }
        }
      }
    }
  }
`;
